import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import QPage from '../../../components/stepperForm/components/ui/QPage';
import QQuestion from '../../../components/stepperForm/components/ui/QQuestion';
import { usePWAInstall } from 'react-use-pwa-install'

const Install = ({ exit }) => {
    const install = usePWAInstall();

    const [platform, setPlatform] = useState("Unknown");

    useEffect(() => {
        // Platform detektálás
        const userAgent = navigator.userAgent.toLowerCase();
        if (/android/.test(userAgent)) {
            setPlatform("Android");
        } else if (/iphone|ipad|ipod/.test(userAgent)) {
            setPlatform("iOS");
        } else if (/win/.test(userAgent)) {
            setPlatform("Windows");
        } else if (/mac/.test(userAgent)) {
            setPlatform("MacOS");
        } else if (/linux/.test(userAgent)) {
            setPlatform("Linux");
        } else {
            setPlatform("Unknown");
        }
    }, []);

    return (
        <QPage exit={() => exit(false)}>

            {platform === 'iOS' &&
                <div className="profileCard">
                    <div className='centerContent2'>
                        <img
                            alt={"PR_VET"}
                            // className={classes.logo}
                            src={'/logo.png'}
                            width={100}
                        />
                        <h2>PR-VET telepítése iPhone készülékre</h2>
                    </div>
                    <ol>
                        <li>
                            Nyissa meg a weboldalt a Safari böngészőben.
                        </li>
                        <li>
                            Érintse meg a megosztás ikont (négyzet, amelyből nyíl mutat felfelé), majd válassza a "Hozzáadás a Főképernyőhöz" opciót.
                        </li>
                        <li>
                            Adja meg az alkalmazás nevét, majd nyomja meg a "Hozzáadás" gombot.
                        </li>
                        <li>
                            Az ikon megjelenik a főképernyőn, amelyről elindítható az alkalmazás.
                        </li>
                    </ol>
                </div>
            }
            {platform !== 'iOS' &&
                <QQuestion >
                    <div className='centerContent'>
                        <div className="profileCard">
                            <>
                                <h1>PR-VET telepítés</h1>
                                {!install && <p>
                                    Az applikáció már telepítve van a telefonra, vagy telepítés alatt áll. Az alkalmazás ikonja megtalálható a kezdőképernyőn.
                                </p>}
                            </>

                            <img
                                alt={"PR_VET"}
                                // className={classes.logo}
                                src={'/logo.png'}
                                width={100}
                            />
                            <p>PR-VET</p>
                        </div>
                        {install && <Button onClick={install}>PR-VET alkalmazás telepítése</Button>}
                    </div>
                </QQuestion>
            }

        </QPage >
    );
};

export default Install;