import React, { useEffect, useState } from 'react';
import { Switch, Button, TextField, FormControl } from '@mui/material';
import UpdateIsAdminOfUserAction from '../../services/actions/UpdateIsAdminOfUserAction';
import UpdateIsSuperAdminOfUserAction from '../../services/actions/UpdateIsSuperAdminOfUserAction';
import UpdateIsManagerOfUserAction from '../../services/actions/UpdateIsManagerOfUserAction';
import UpdateUserFieldAction from '../../services/actions/UpdateUserNameAction';

const UserData = ({ userProfile, getProfile, profile }) => {
    const [changes, setChanges] = useState({
        isAdmin: userProfile?.isAdmin,
        isManager: userProfile?.isManager,
        isSuperAdmin: userProfile?.isSuperAdmin
    });
    const { subscribe } = UpdateIsAdminOfUserAction();
    const { subscribe: subscribe2 } = UpdateIsManagerOfUserAction();
    const { subscribe: subscribe3 } = UpdateIsSuperAdminOfUserAction();
    const { subscribe: subscribeField } = UpdateUserFieldAction();
    const isSuperAdmin = profile?.isSuperAdmin;
    const isManager = profile?.isManager;
    const isAdmin = profile?.isAdmin;
    const isSameUser = profile?.id === userProfile?.id;

    const handleChange = (key, value) => {
        setChanges(prevChanges => ({
            ...prevChanges,
            [key]: value
        }));
    };

    useEffect(() => {
        if (userProfile) {
            setChanges({
                name: userProfile?.name,
                isAdmin: userProfile?.isAdmin,
                isManager: userProfile?.isManager,
                isSuperAdmin: userProfile?.isSuperAdmin,
                isEditor: userProfile?.isEditor,
                testUser: userProfile?.testUser,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);
    const applyChanges = async (key) => {
        switch (key) {
            case 'isAdmin':
                await subscribe({ id: userProfile.id, isAdmin: changes?.isAdmin });
                await getProfile(true, true);
                break;
            case 'isManager':
                await subscribe2({ id: userProfile.id, isManager: changes?.isManager });
                await getProfile(true, true);
                break;
            case 'isSuperAdmin':
                await subscribe3({ id: userProfile.id, isSuperAdmin: changes?.isSuperAdmin });
                await getProfile(true, true);
                break;
            case 'name':
                await subscribeField({ id: userProfile.id, field: 'name', value: changes['name'] });
                await getProfile(true, true);
                break;
            case 'isEditor':
                await subscribeField({ id: userProfile.id, field: 'isEditor', value: changes['isEditor'] });
                await getProfile(true, true);
                break;
            case 'testUser':
                await subscribeField({ id: userProfile.id, field: 'testUser', value: changes['testUser'] });
                await getProfile(true, true);
                break;
            default:
                break;
        }
        setChanges(prevChanges => {
            const updatedChanges = { ...prevChanges };
            delete updatedChanges[key];
            return updatedChanges;
        });
    };

    return (
        <div style={{ textAlign: 'left' }}>
            <div>
                <h3>Név</h3>
                <FormControl variant="filled">
                    <TextField
                        disabled={(!isManager && !isSuperAdmin)}
                        label="Felhasználó neve"
                        value={changes?.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        // onChange={(e) => setName(e.target.value)}
                        error={changes?.name?.trim().length < 6}
                        helperText={changes?.name?.trim().length < 6 ? "Min. 6 karakter hosszúnak kell lennie" : ""}
                    />
                </FormControl>
                {userProfile && changes?.name?.trim().length >= 6 && (changes?.name !== userProfile?.name) && (isAdmin || isManager || isSuperAdmin) &&
                    < Button
                        disabled=
                        {
                            changes?.name.length < 6 ||
                            (changes?.name === userProfile?.name) ||
                            (!isManager && !isSuperAdmin)
                        }
                        // variant="outlined"
                        onClick={() => applyChanges('name')}>
                        Mentés
                    </Button>
                }
                <h3>Jogosultságok</h3>
                <div>
                    <Switch
                        checked={changes?.isAdmin ? true : false}
                        onChange={(e) => handleChange('isAdmin', e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={!userProfile || isSameUser || !(isSuperAdmin || isManager)}
                    /> Oktató
                    {userProfile && changes?.isAdmin !== userProfile?.isAdmin &&
                        <Button onClick={() => applyChanges('isAdmin')}>Alkalmaz</Button>
                    }
                </div>
                <div>
                    <Switch
                        checked={changes?.isManager ? true : false}
                        onChange={(e) => handleChange('isManager', e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={!userProfile || isSameUser || !isSuperAdmin}
                    /> Ügyintéző
                    {userProfile && changes?.isManager !== userProfile?.isManager &&
                        <Button onClick={() => applyChanges('isManager')}>Alkalmaz</Button>
                    }
                </div>
                <div>
                    <Switch
                        checked={changes?.isSuperAdmin ? true : false}
                        onChange={(e) => handleChange('isSuperAdmin', e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={!userProfile || isSameUser || !isSuperAdmin}
                    /> Intézményi ügyintéző
                    {userProfile && changes?.isSuperAdmin !== userProfile?.isSuperAdmin &&
                        <Button onClick={() => applyChanges('isSuperAdmin')}>Alkalmaz</Button>
                    }
                </div>
                <div>
                    <Switch
                        checked={changes?.isEditor ? true : false}
                        onChange={(e) => handleChange('isEditor', e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={!userProfile || isSameUser || !isSuperAdmin}
                    /> Szerkesztő
                    {userProfile && changes?.isEditor !== userProfile?.isEditor &&
                        <Button onClick={() => applyChanges('isEditor')}>Alkalmaz</Button>
                    }
                </div>
                <div>
                    <Switch
                        checked={changes?.testUser ? true : false}
                        onChange={(e) => handleChange('testUser', e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={!userProfile || isSameUser || !isSuperAdmin}
                    /> Teszt felhasználó
                    {userProfile && changes?.testUser !== userProfile?.testUser &&
                        <Button onClick={() => applyChanges('testUser')}>Alkalmaz</Button>
                    }
                </div>
            </div>
        </div >
    );
};

export default UserData;
