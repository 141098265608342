import { Button, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UpdateUserFieldAction from '../../../services/actions/UpdateUserNameAction';

const SetupEmails = ({ userProfile }) => {
    const { subscribe: subscribeField } = UpdateUserFieldAction();
    const [changes, setChanges] = useState({
        emailNewQuestionnaire: userProfile?.emailNewQuestionnaire,
        emailNewEvaluation: userProfile?.emailNewEvaluation
    });

    const applyChanges = async (key) => {
        switch (key) {
            case 'emailNewQuestionnaire':
                await subscribeField({ id: userProfile.id, field: 'emailNewQuestionnaire', value: changes['emailNewQuestionnaire'] });
                break;
            case 'emailNewEvaluation':
                await subscribeField({ id: userProfile.id, field: 'emailNewEvaluation', value: changes['emailNewEvaluation'] });
                break;
            default:
                break;
        }
    };

    const handleChange = (key, value) => {
        setChanges(prevChanges => ({
            ...prevChanges,
            [key]: value
        }));
    };

    useEffect(() => {
        if (userProfile) {
            setChanges({
                emailNewQuestionnaire: userProfile?.emailNewQuestionnaire,
                emailNewEvaluation: userProfile?.emailNewEvaluation
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    return (
        <div style={{ textAlign: 'left' }}>
            <h3>Értesítések</h3>

            <div>
                <Switch
                    checked={changes?.emailNewQuestionnaire ? true : false}
                    onChange={(e) => handleChange('emailNewQuestionnaire', e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                /> Email: új kitöltés
                {userProfile && changes?.emailNewQuestionnaire !== userProfile?.emailNewQuestionnaire &&
                    <Button onClick={() => applyChanges('emailNewQuestionnaire')}>Alkalmaz</Button>
                }
            </div>
            <div>
                <Switch
                    checked={changes?.emailNewEvaluation ? true : false}
                    onChange={(e) => handleChange('emailNewEvaluation', e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                /> E-mail: új értékelés
                {userProfile && changes?.emailNewEvaluation !== userProfile?.emailNewEvaluation &&
                    <Button onClick={() => applyChanges('emailNewEvaluation')}>Alkalmaz</Button>
                }
            </div>
        </div>
    );
};

export default SetupEmails;