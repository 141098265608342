import React, { useContext, useState } from "react";
import { AddAPhoto, FolderDelete, GroupAdd, Groups, InstallMobile, QrCode, SecurityUpdate } from '@mui/icons-material';
import { Switch, Grid, BottomNavigationAction } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import FSPFieldSelect from "../../../components/stepperSampling/components/FSPField/components/FSPFieldSelect";
import { AppContext } from "../../../services/appContext";

import ProfileAvatar from "./ProfileAvatar";
import Install from "./Install";

const Setup = ({ auth }) => {
    const navigate = useNavigate();
    const [show, setShow] = useState();
    const {
        showErrorIcon, setShowErrorIcon,
        sliderView, setSliderView,
        theme, setTheme
    } = useContext(AppContext);

    const appUpdate = () => {
        console.log('cache', caches)
        caches.keys().then((keys) => {
            keys.forEach((request, index, array) => {
                caches.delete(request);
                console.log('index', request)
            });
        });
        window.location.reload(true);
        toast.success(`Frissítjük az alkalmazást`);
    }

    const clearLS = () => {
        const selectedAppName = localStorage.getItem('selectedApp') ?? 'firstApp';
        const firebaseApps = localStorage.getItem('firebaseAppsV2');
        localStorage.clear();
        localStorage.setItem('selectedApp', selectedAppName);
        localStorage.setItem('firebaseAppsV2', firebaseApps);
        window.location.reload(true);
        toast.success(`Töröljük a mentett adatokat`);
    }

    const handleChange = (event) => {
        const s = event.target.checked ? 1 : 0;
        setSliderView(event.target.checked);
        localStorage.setItem('sliderView', s);
    };

    const handleChange2 = (event) => {
        const s = event.target.checked ? 1 : 0;
        setShowErrorIcon(event.target.checked);
        localStorage.setItem('showErrorIcon', s);
    };

    const handleChangeTheme = (event) => {
        const { value } = event.target;
        setTheme(value);
        localStorage.setItem('theme', value);
    };

    return (
        <div style={{ textAlign: 'left' }}>
            <Grid item xs={12}>
                <h3>Applikáció</h3>
            </Grid>
            <Grid className="profileButton" container spacing={2}>
                <Grid item xs={3}>
                    <BottomNavigationAction showLabel onClick={() => { navigate("/app") }} label="Applikáció QR kód" icon={<QrCode />} />
                </Grid>
                <Grid item xs={3}>
                    <BottomNavigationAction showLabel onClick={() => { appUpdate() }} label="Applikáció frissítése" icon={<SecurityUpdate />} />
                </Grid>
                <Grid item xs={3}>
                    <BottomNavigationAction showLabel onClick={() => setShow('install')} label="Applikáció telepítése" icon={<InstallMobile />} />
                </Grid>
                <Grid className="profileButton" item xs={3}>
                    <BottomNavigationAction showLabel onClick={() => { clearLS() }} label="Local storage törlése" icon={<FolderDelete />} />
                </Grid>
            </Grid>
            <h3>Beállítások</h3>
            <Grid className="profileButton" container spacing={2}>
                <Grid className="profileButton" item xs={3}>
                    <BottomNavigationAction showLabel onClick={() => { setShow('profile') }} label="Új profilkép" icon={<AddAPhoto />} />
                </Grid>
                <Grid item xs={3}>
                    <BottomNavigationAction showLabel onClick={() => { navigate("/mygroups") }} label="Csoportjaim" icon={<Groups />} />
                </Grid>
                <Grid item xs={3}>
                    <BottomNavigationAction showLabel onClick={() => { navigate("/myprofile") }} label="QR kódom" icon={<QrCode />} />
                </Grid>

                <Grid item xs={3}>
                    <BottomNavigationAction showLabel onClick={() => (navigate("/subscribe"))} label="Jelentkezés csoportba" icon={<GroupAdd />} />
                </Grid>
                {/* <Grid item xs={4}>
                <BottomNavigationAction disabled={true} showLabel onClick={() => (console.log('sds'))} label="Beállítások" icon={<PhonelinkSetup />} />
              </Grid> */}
            </Grid>
            <div>
                <h3>Nézet</h3>
                <div style={{ justifyContent: 'left', maxWidth: '200px' }}>
                    <FSPFieldSelect
                        id={'name'}
                        label={''}
                        placeholder={'placeholder'}
                        options={
                            {
                                "standard": { "id": "standard", "name": "Standard" },
                                "modern": { "id": "modern", "name": "Modern" },
                            }
                        }
                        valuesKey={'id'}
                        addId={false}
                        valuesLabel={'name'}
                        value={theme}
                        handleChange={handleChangeTheme}
                    />
                </div>
                <Switch
                    checked={sliderView}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    label="Gesztusvezérelt kitöltés"
                /> Gesztusvezérelt kitöltés
                <div>
                    <Switch
                        checked={showErrorIcon}
                        onChange={handleChange2}
                        inputProps={{ 'aria-label': 'controlled' }}
                        label="Gesztusvezérelt kitöltés"
                    /> Visszajelzés ikon
                </div>
            </div>
            {show === 'profile' && <ProfileAvatar auth={auth} exit={() => (setShow(false))} />}
            {show === 'install' && <Install auth={auth} exit={() => (setShow())} />}

        </div>
    );
};

export default Setup;