import React, { useState } from 'react';
import { apps } from '../../data/apps';
import { v4 as uuidv4 } from "uuid";

const getApps = () => {
    const oldfirebaseApps = JSON.parse(localStorage.getItem('firebaseAppsV2'));
    if (oldfirebaseApps !== null && typeof oldfirebaseApps === 'object') {
        return oldfirebaseApps
    } else {
        localStorage.setItem('firebaseAppsV2', JSON.stringify(apps));
        return apps;
    }
}

const getDeviceId = () => {
    const oldDeviceId = localStorage.getItem('deviceId');
    if (oldDeviceId !== null && typeof oldDeviceId === 'string') {
        return oldDeviceId
    } else {
        const uuid = uuidv4();
        localStorage.setItem('deviceId', uuid);
        return uuid;
    }
}
const Counters = () => {

    const [newEvaluationMonth, setNewEvaluationMonth] = useState(0)
    const [newQuestionnaires, setNewQuestionnaires] = useState(0)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isManager, setIsManager] = useState(false)
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [showErrorIcon, setShowErrorIcon] = useState(localStorage.getItem('showErrorIcon') != undefined ? localStorage.getItem('showErrorIcon') == 1 : false);
    const [firebaseApps, setFirebaseApps] = useState(getApps());
    const [selectedApp, setSelectedApp] = useState(localStorage.getItem('selectedApp') ?? 'firstApp');
    const [sliderView, setSliderView] = useState(localStorage.getItem('sliderView') != undefined ? localStorage.getItem('sliderView') == 1 : true);
    const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'standard');
    const [messageQuestionnaires, setMessageQuestionnaires] = useState(localStorage.getItem('messageQuestionnaires') != undefined ? localStorage.getItem('messageQuestionnaires') == 1 : false);
    const [messageEvaluations, setMessageEvaluations] = useState(localStorage.getItem('messageEvaluations') != undefined ? localStorage.getItem('messageEvaluations') == 1 : false);
    const [allEvaluationsData, setAllEvaluationsData] = useState(null);
    const [emailQuestionnaires, setEmailQuestionnaires] = useState(localStorage.getItem('emailQuestionnaires') != undefined ? localStorage.getItem('emailQuestionnaires') == 1 : false);
    const [emailEvaluations, setEmailEvaluations] = useState(localStorage.getItem('emailEvaluations') != undefined ? localStorage.getItem('emailEvaluations') == 1 : false);
    const [debug, setDebug] = useState(localStorage.getItem('debug') != undefined ? localStorage.getItem('debug') == 1 : false);
    const [oldEvaluations, setOldEvaluations] = useState(null);
    const [evaluations, setEvaluations] = useState(null);
    const [profile, setProfile] = useState(null);
    const [evaluationsMonthsNumber, setEvaluationsMonthsNumber] = useState(1 + Math.round(window.innerHeight / 125));
    const [newEvaluation, setNewEvaluation] = useState(0)
    const [deviceId, setDeviceId] = useState(getDeviceId());
    const [isTokenFound, setTokenFound] = useState(false);
    const [profileTab, setProfileTab] = React.useState(0);
    const [profileUserTab, setProfileUserTab,] = React.useState('groups');
    const [groupTab, setGroupTab] = React.useState('qr');
    const [facingMode, setFacingMode] = useState(localStorage.getItem('facingMode') ?? 'default');
    const [viewStatusOfGroups, setViewStatusOfGroups] = useState(true);
    const [version] = React.useState('v0.26.03');

    return {
        newEvaluation, setNewEvaluation,
        newEvaluationMonth, setNewEvaluationMonth,
        isAdmin, setIsAdmin,
        isManager, setIsManager,
        isSuperAdmin, setIsSuperAdmin,
        newQuestionnaires, setNewQuestionnaires,
        selectedApp, setSelectedApp,
        sliderView, setSliderView,
        theme, setTheme,
        messageQuestionnaires, setMessageQuestionnaires,
        messageEvaluations, setMessageEvaluations,
        emailQuestionnaires, setEmailQuestionnaires,
        emailEvaluations, setEmailEvaluations,
        debug, setDebug,
        allEvaluationsData, setAllEvaluationsData,
        oldEvaluations, setOldEvaluations,
        evaluations, setEvaluations,
        profile, setProfile,
        evaluationsMonthsNumber, setEvaluationsMonthsNumber,
        firebaseApps, setFirebaseApps,
        deviceId, setDeviceId,
        isTokenFound, setTokenFound,
        profileTab, setProfileTab,
        profileUserTab, setProfileUserTab,
        groupTab, setGroupTab,
        facingMode, setFacingMode,
        showErrorIcon, setShowErrorIcon,
        viewStatusOfGroups, setViewStatusOfGroups,
        version
    };
};

export default Counters;