import React, { useEffect, useState } from 'react';
import LightSpeed from 'react-reveal/Pulse';
import { Box } from '@mui/material';

const QuestionMini = ({ field }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(field);
    }, [field]);

    return (
        <LightSpeed down opposite when={field === loading}>
            <Box
                sx={{
                    // minHeight: 80,
                }}>
                <div className='question'>
                    {field?.name}
                </div>
            </Box>
        </LightSpeed>
    );
};

export default QuestionMini;