import { useContext, useEffect, useState, useRef } from 'react';
import { onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Alert, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import Tada from 'react-reveal/Tada';
import { auth, deleteToken, messaging } from '../../firebase.config';
import { AppContext } from '../appContext';
import UpdateEmailVerificationCounterAction from '../actions/UpdateEmailVerificationCounterAction';
import { MarkEmailRead } from '@mui/icons-material';
import ColourFull from '../../commons/loaders/ColourFull';
import DeleteToken from '../actions/DeleteToken';
import { useNavigate } from 'react-router-dom';

const AuthStateChanged = () => {
    const [user] = useAuthState(auth);
    const { snapshots, isTokenFound, deviceId } = useContext(AppContext);
    const { subscribe } = UpdateEmailVerificationCounterAction();
    const [email, setEmail] = useState();
    const [emailVerified, setEmailVerified] = useState(true);
    const [emailVerifiedCounter, setEmailVerifiedCounter] = useState(null);
    const [countdown, setCountdown] = useState(10);
    const [skip, setSkip] = useState(false);
    const intervalIdRef = useRef();
    const { deleteAction } = DeleteToken();
    const navigate = useNavigate();

    useEffect(() => {
        const profileData = snapshots && snapshots['profile'] && snapshots['profile'][0] ? snapshots['profile'][0]?.data : null;
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setEmail(user?.email);
            if (user && profileData) {
                if (!user.emailVerified && (profileData?.testUser !== 1 && !profileData?.testUser)) {
                    startCountdown();
                    if (!skip) {
                        setEmailVerified(false);
                    }
                } else {
                    setEmailVerified(true);
                }
            } else {
                setEmailVerified(true);
            }
        });
        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, snapshots['profile']]);

    useEffect(() => {
        const profileData = snapshots && snapshots['profile'] && snapshots['profile'][0] ? snapshots['profile'][0]?.data : null;
        if (user?.uid && profileData) {
            const counter = profileData?.emailVerifiedCounter ?? 5;
            setEmailVerifiedCounter(counter);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, snapshots['profile']]);

    const handleResendVerificationEmail = async () => {
        try {
            const user = auth.currentUser;
            await sendEmailVerification(user);
        } catch (error) {
            console.error("Email resend error:", error.message);
        }
    };

    const onClose = async () => {
        setEmailVerified(true);
        setSkip(true);
        subscribe({
            id: user?.uid,
            emailVerifiedCounter: emailVerifiedCounter - 1
        })
    };

    const startCountdown = () => {
        intervalIdRef.current = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown < 1) {
                    clearInterval(intervalIdRef.current);
                }
                return prevCountdown - 1;
            });
        }, 2000);
    };

    useEffect(() => {
        if (countdown < 1) {
            clearInterval(intervalIdRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdown]);

    const onLogout = () => {
        if (isTokenFound) {
            console.log('id', deviceId);
            deleteToken(messaging);
            console.log('isTokenFound', isTokenFound);
            deleteAction({
                id: deviceId,
                // token: isTokenFound
            })
        }
        auth.signOut();
        console.log('location', 'location');
        navigate("/sign-in");
    };

    return (
        <>
            {(!skip || !emailVerified) && (
                <Dialog fullScreen open={!emailVerified}>
                    <DialogContent>
                        <div className='centerContent'>
                            <Typography variant="h4">
                                E-mail cím megerősítése
                            </Typography>
                            <br />
                            <Typography>
                                Az Ön e-mail címét (<span style={{ fontWeight: 'bold' }}>{email}</span>) még nem erősítették meg. Kérjük, véglegesítse a megerősítést fiókjában, hogy teljes körűen élvezhesse a rendszer előnyeit.
                            </Typography>
                            <Tada>
                                <MarkEmailRead sx={{ fontSize: 120 }} color="success" />
                            </Tada>

                            {emailVerifiedCounter > 0 &&
                                <Alert severity="warning" className="centerContent2">
                                    <Typography>
                                        Email megerősítés nélkül még {emailVerifiedCounter} alkalommal használhatja a rendszert.
                                    </Typography>
                                </Alert>
                            }
                            {emailVerifiedCounter < 1 &&
                                <Alert severity="warning" className="centerContent2">
                                    <Typography color="error">
                                        A rendszer teljes körű használatához szükséges az e-mail cím megerősítése.
                                    </Typography>
                                    <ColourFull />
                                </Alert>
                            }

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary" disabled={emailVerifiedCounter < 1 || countdown > 0}>
                            Mégsem {countdown > 0 && `(${countdown})`}
                        </Button>
                        <Button onClick={onLogout}  color="primary">
                            Kilépés
                        </Button>
                        <Button onClick={handleResendVerificationEmail} variant="contained" color="primary">
                            E-mail újraküldése
                        </Button>
                    </DialogActions>
                </Dialog>

            )}
        </>
    );
};

export default AuthStateChanged;
