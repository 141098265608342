import React, { useContext, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { Close, AspectRatio } from '@mui/icons-material';
import { pink } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../services/appContext';
import CIconButton from './buttons/CIconButton';
import NextButton from './buttons/NextButton';

const CheckScreenSize = () => {
    const {
        sliderView, setSliderView,
    } = useContext(AppContext);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        console.log('aaa', window.innerHeight);
        if (sliderView && window.innerHeight < 800) {
            setOpen(true);
        }
    }, [sliderView]);

    const handleSwitchToTraditional = () => {
        localStorage.setItem('sliderView', false);
        setSliderView(false);
        setOpen(false);
    };

    return (
        <Dialog fullScreen open={open}>
            <CIconButton
                icon={<Close />}
                color={'primary'}
                confirm={false}
                onClick={() => { navigate(`/questionnaires`) }}
                style={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    zIndex: 99999
                }}
            />
            <div className='centerContent' >
                <h1>Képernyő méret figyelmeztetés</h1>
                <p>{`Szeretnénk felhívni a figyelmet arra, hogy a kis képernyőméret miatt előfordulhat, hogy a kérdőív bizonyos részei nem jelennek meg megfelelően, ami akadályozhatja a kitöltést. A probléma elkerülése érdekében javasoljuk, hogy váltson a 'hagyományos' nézetre.`}</p>
                <AspectRatio sx={{ fontSize: 70, color: pink[500] }} />
                <div>
                    <NextButton
                        name={'Hagyományos nézet'}
                        variant={'contained'}
                        onClick={() => {
                            handleSwitchToTraditional();
                        }}
                    />
                </div>
                <div>
                    <NextButton
                        name={'Mégsem'}
                        variant={'contained'}
                        className={"secondaryButton2"}
                        onClick={() => {
                            handleClose(false)
                        }}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default CheckScreenSize;