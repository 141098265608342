import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, List } from '@mui/material';
import { green } from '@mui/material/colors';
import Instruction from './components/Instruction';
import Question from './components/Question';
import QuestionMini from './components/QuestionMini';

const FSPFieldMultipleSelectScale = ({
    field,
    options,
    handleChange,
    values,
    page
}) => {
    const screenHeight = window.screen.height;
    const setValue = (v) => {
        if (values.indexOf(v) < 0) {
            handleChange(field.name, [...values, v], false)
        } else {
            const rest = values.filter(i => i !== v)
            handleChange(field.name, rest, false)
        }
    }

    return (<>
        {/* <h3 className='question'>
            {field.name}
            <br /><Instruction text={page?.instruction} />
        </h3> */}
        {screenHeight >= 800 ?
            <Question field={field} />
            :
            <QuestionMini field={field} />
        }
        <List
            dense
            // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            sx={{
                // width: '90%',
                // maxWidth: 360,
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                // maxHeight: 360,
                // maxHeight: `calc(100vh - 260px)`,
                // color: pink[800],
                fontSize: '33',
                '& .MuiSvgIcon-root': { fontSize: 28 },
                '&.Mui-checked': {
                    color: green[600],
                },
                '& ul': { margin: 5, fontSize: '2.25rem', },
                '& li': {
                    padding: 0,
                    margin: 0,
                    fontweight: 600,
                    // height:50,
                    fontSize: '2.25rem',
                },
            }}>

            <FormGroup>
                {options.map((option, index) => (
                    <>
                        <FormControlLabel
                            key={`FormControlLabel${index}`}
                            control={
                                <Checkbox
                                    key={`Checkbox${index}`}
                                    sx={{
                                        paddingTop: 0,
                                        marginTop: 0,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                        fontweight: 300,
                                    }}
                                    checked={values.indexOf(option.value) > -1}
                                    onChange={() => { setValue(option.value) }}
                                    name={option.name}
                                />
                            }
                            label={
                                <>
                                    <span key={`span${index}`} className={'listOption'}>
                                        {option.name}
                                    </span>
                                </>
                            }
                        />
                    </>
                ))}
            </FormGroup>
        </List>
    </>
    );
};

export default FSPFieldMultipleSelectScale;