import React from 'react';
import MiniStepper from './components/MiniStepper';
import NormalStepper from './components/NormalStepper';

const QStepper = ({ mini = false,
    lastSlideIsVisible,
    steps,
    styles,
    activeStep = 0,
    previous,
    next,
    valid
}) => {
    const screenHeight = window.screen.height;
    return (<>
        {screenHeight >= 800 && steps.length < 7 && !mini ? (
            <NormalStepper
                lastSlideIsVisible={lastSlideIsVisible}
                steps={steps}
                activeStep={activeStep}
                previous={previous}
                next={next}
                valid={valid}
                styles={styles}
            />
        ) : (
            <MiniStepper
                lastSlideIsVisible={lastSlideIsVisible}
                steps={steps}
                activeStep={activeStep}
                previous={previous}
                next={next}
                valid={valid}
            />
        )}
    </>)
};

export default QStepper;